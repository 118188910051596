import { AF_PRODUCT_ROUTES } from './constants';
import { afVariables } from '../styles/cssVariables/af';
import commonAF from 'styles/cssVariables/commonAF.json';
import { ROUTE_ITEMS } from './routeItems';

export const CONFIG = {
  ANALYTICS_APP_URL: 'https://analytics.aquafacts.com/',
  APP_ABBR: 'AF',
  APP_NAME: 'AquaFacts',
  // Misc
  CONSOLE_COLOR: 'RosyBrown',
  FB_APP_ID: '1069430403592973',
  FLEET: 'list',
  FLEETS: 'lists',
  VESSEL_NAME_ZOOM_THRESHOLD: 9,
  // Storage/Settings
  STORE_SETTINGS_KEY: 'AF_COM',
  STORE_AUTH_KEY: 'AF_COM_AUTH',
  MAIN_FILTER: 'AF_MAIN_FILTER',
  // Html elements
  HEADER_LOGO: '/assets/logo/LogoAF.svg',
  INTRO_LOGO: '/assets/logo/Logo-2AF.svg',
  IS_AF: true,
  REPORT: {
    ANALYTICS: {
      PROD: 'https://tableau.aquafacts.com/trusted/%%TOKEN%%/views/AquaFactsAnalytics/OperationalMarketOverview',
      TEST: 'https://tableau.fishfacts.com/trusted/%%TOKEN%%/views/AquaFacts_Analytics_Staging/IncomeStatement'
    }
  },
  ROUTES: {
    _ABOUT: '/service/provider/243'
  },
  // Styles
  CSS_VARS: commonAF,
  THEME: afVariables,
  // LandingPage nav links
  LANDING_PAGE_NAV_CATEGORIES: [
    {
      imageSrc: '/assets/home/MapAF.png',
      path: AF_PRODUCT_ROUTES.MAP,
      title: 'Map'
    },
    {
      imageSrc: '/assets/home/Harvesting.png',
      path: AF_PRODUCT_ROUTES.HARVESTING,
      title: 'Harvesting'
    },
    {
      imageSrc: '/assets/home/HistoricalTrackAF.png',
      path: AF_PRODUCT_ROUTES.HISTORICAL_TRACK,
      title: 'Historical Track'
    },
    {
      imageSrc: '/assets/home/VesselsAF.png',
      path: AF_PRODUCT_ROUTES.VESSELS,
      title: 'Vessels'
    },
    {
      imageSrc: '/assets/home/Companies.png',
      path: AF_PRODUCT_ROUTES.COMPANIES,
      title: 'Companies'
    },
    {
      imageSrc: '/assets/home/Biolytics.png',
      path: AF_PRODUCT_ROUTES.BIOLYTICS,
      title: 'Biolytics'
    },
    {
      imageSrc: '/assets/home/AnalyticsAF.png',
      path: AF_PRODUCT_ROUTES.ANALYTICS,
      title: 'Analytics'
    },
    {
      imageSrc: '/assets/home/Facilities.png',
      path: AF_PRODUCT_ROUTES.FACILITIES,
      title: 'Facilities'
    },
    {
      imageSrc: '/assets/home/CRMAF.png',
      path: AF_PRODUCT_ROUTES.CRM,
      title: 'CRM'
    },
    {
      imageSrc: '/assets/home/ServicesAF.png',
      path: AF_PRODUCT_ROUTES.SERVICES,
      title: 'Service Providers'
    },
    {
      imageSrc: '/assets/home/NewsAF.png',
      path: AF_PRODUCT_ROUTES.NEWS,
      title: 'News'
    }
  ],
  // Nav menu links
  NAVIGATION: [
    ROUTE_ITEMS.HOME,
    ROUTE_ITEMS.MAP,
    ROUTE_ITEMS.VESSELS,
    {
      title: 'Analysis',
      key: 'analysis',
      icon: 'bar-chart',
      items: [
        ROUTE_ITEMS.HARVESTING,
        ROUTE_ITEMS.BIOLYTICS,
        ROUTE_ITEMS.ANALYTICS,
        ROUTE_ITEMS.HISTORICAL_TRACK
      ]
    },
    {
      title: 'Service Providers',
      key: 'services',
      icon: 'table',
      items: [ROUTE_ITEMS.SERVICES, ROUTE_ITEMS.CRM]
    },
    ROUTE_ITEMS.CUSTOM_SCANBIO
  ],
  NAVIGATION_ANON: [
    ROUTE_ITEMS.HOME,
    {
      path: AF_PRODUCT_ROUTES.MAP,
      title: 'Map',
      icon: 'map'
    },
    {
      path: AF_PRODUCT_ROUTES.VESSELS,
      title: 'Vessels',
      icon: 'vessel'
    },
    {
      title: 'Analysis',
      key: 'analysis',
      icon: 'bar-chart',
      items: [
        {
          path: AF_PRODUCT_ROUTES.HARVESTING,
          title: 'Harvesting'
        },
        {
          path: AF_PRODUCT_ROUTES.BIOLYTICS,
          title: 'Biolytics'
        },
        {
          path: AF_PRODUCT_ROUTES.ANALYTICS,
          title: 'Finance'
        },
        {
          path: AF_PRODUCT_ROUTES.HISTORICAL_TRACK,
          title: 'Track'
        }
      ]
    },
    {
      title: 'Service Providers',
      key: 'services',
      icon: 'table',
      items: [
        ROUTE_ITEMS.SERVICES,
        {
          path: AF_PRODUCT_ROUTES.CRM,
          title: 'CRM'
        }
      ]
    }
  ],
  MOBILE_APP: {
    APP_STORE: 'https://apps.apple.com/us/app/aquafacts/id1605595722',
    GOOGLE_PLAY:
      'https://play.google.com/store/apps/details?id=com.aquafacts.aquafactsapp'
  }
};
